import React, { useState, useRef, useCallback } from "react";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  PreviewCard,
  RSelect,
  Icon,
  BackTo,
} from "../../components/Component";
import Webcam from "react-webcam";
import Logo from "../../images/logo.png";
import Avatar from "../../images/avatar/avatar.png";
import MaskedInput from "react-text-mask";
import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import {
  Spinner,
  FormGroup,
  Label,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Badge,
} from "reactstrap";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { getRandomPin } from "../../utils/Utils";

const RegisterPatient = ({ history }) => {
  const [avatar, setAvatar] = useState(Avatar);
  const [loading, setLoading] = useState(false);
  const [selectsData, setSelectsData] = useState({});
  const { control, errors, register, handleSubmit, setValue } = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPayment, setIsOpenPayment] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const webcamRef = useRef(null);
  const [rangeStart, setRangeStart] = useState(new Date());
  const [rangeEnd, setRangeEnd] = useState();
  const [birthday, setBirthday] = useState();

  const [patientId, setPatientId] = useState();

  const [ceps, setCeps] = useState({
    cep: "",
    cep_responsable: "",
  });

  const [cpfs, setCpfs] = useState({
    cpf: "",
    cpf_responsable: "",
  });

  const [phones, setPhones] = useState([
    {
      phone: "",
      name: "",
    },
  ]);

  registerLocale("ptBR", ptBR);

  const fillCEP = (cep, where) => {
    axios.get(`${process.env.REACT_APP_PROTOCOL}://viacep.com.br/ws/${cep}/json/`).then((data) => {
      if (where === "patient") {
        setValue("address", data.data.logradouro);
        setValue("city", data.data.localidade);
        setValue("address_neighborhood", data.data.bairro);
        setValue("state", data.data.uf);
      } else {
        setValue("address_responsable", data.data.logradouro);
        setValue("city_responsable", data.data.localidade);
        setValue("address_neighborhood_responsable", data.data.bairro);
        setValue("state_responsable", data.data.uf);
      }
    });
  };

  const addCeps = (cep, type) => {
    setCeps({
      ...ceps,
      [type === "patient" ? "cep" : "cep_responsable"]: cep,
    });
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const togglePayment = () => {
    setIsOpenPayment(!isOpenPayment);
  };

  const handleFormSubmit = (data) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/patients`, {
        data: {
          ...data,
          ...selectsData,
          phones,
          start_date: rangeStart,
          end_date: rangeEnd,
          avatar_base64: avatar,
          cpf: cpfs.cpf,
          cpf_responsable: cpfs.cpf_responsable,
          birthday,
          ...ceps,
          code: getRandomPin("123456789", 4),
        },
      })
      .then((response) => {
        setPatientId(response.data.data.id);
        setIsOpenPayment(false);
        setLoading(false);
        saveContract(data, response.data.data.id);
      });
  };

  const saveContract = async (data, id) => {
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/contracts`, {
        data: {
          ...data,
          ...selectsData,
          phones,
          ...ceps,
          start_date: rangeStart,
          end_date: rangeEnd,
          cpf: cpfs.cpf,
          cpf_responsable: cpfs.cpf_responsable,
          patient: {
            connect: [id],
          },
          code: uuidv4(),
        },
      })
      .then((response) => {
        setLoading(false);
        setIsOpenPayment(false);
        setModalSuccess(true);
      });
  };

  const addPhone = () => {
    setPhones([
      ...phones,
      {
        phone: null,
        name: null,
      },
    ]);
  };

  const addCpfs = (cpf, type) => {
    setCpfs({
      ...cpfs,
      [type === "patient" ? "cpf" : "cpf_responsable"]: cpf,
    });
  };

  const renderPlans = () => {
    switch (selectsData.plan) {
      case "essencial":
        return (
          <Badge className="badge-md" color="success">
            Essencial
          </Badge>
        );
      case "premium":
        return (
          <Badge className="badge-md" color="primary">
            Premium
          </Badge>
        );

      case "master":
        return (
          <Badge className="badge-md" color="warning">
            Master
          </Badge>
        );

      case "platinum":
        return (
          <Badge className="badge-md" color="gray">
            Platinum
          </Badge>
        );

      default:
        return (
          <Badge className="badge-md" color="light">
            Sem plano definido
          </Badge>
        );
    }
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setAvatar(imageSrc);
    setIsOpen(false);
  }, [webcamRef]);

  return (
    <React.Fragment>
      <Modal
        size="lg"
        isOpen={isOpen}
        toggle={(e) => {
          e.preventDefault();
          toggle();
        }}
      >
        <ModalHeader
          toggle={(e) => {
            e.preventDefault();
            toggle();
          }}
          close={
            <button
              className="close"
              onClick={(e) => {
                e.preventDefault();
                toggle();
              }}
            >
              <Icon name="cross" />
            </button>
          }
        >
          Capturar foto do paciente
        </ModalHeader>
        <ModalBody>
          <Webcam
            height={600}
            width={600}
            style={{ width: "100%" }}
            ref={webcamRef}
          />
        </ModalBody>
        <ModalFooter
          style={{
            justifyContent: "center",
          }}
        >
          <Button
            onClick={(e) => {
              e.preventDefault();
              capture();
            }}
            className="btn-round btn-icon"
            color="primary"
            size="lg"
          >
            <Icon name="camera" />
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalSuccess}>
        <ModalBody className="modal-body-lg text-center">
          <div className="nk-modal">
            <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-check bg-success"></Icon>
            <h4 className="nk-modal-title">Paciente adicionado com sucesso</h4>
            <div className="nk-modal-text">
              {/* <div className="caption-text">
                          You’ve successfully bought <strong>0.5968</strong> BTC for <strong>200.00</strong> USD
                        </div> */}
              <span className="sub-text-sm">
                Você será redirecionado para a listagem de pacientes...
              </span>
            </div>
            <div className="nk-modal-action">
              <Button
                color="primary"
                size="lg"
                className="btn-mw"
                onClick={() => {
                  history.push(`${process.env.PUBLIC_URL}/pacientes`);
                }}
              >
                OK
              </Button>
            </div>
          </div>
        </ModalBody>
        {/* <ModalFooter className="bg-light">
          <div className="text-center w-100">
            <p>
              Earn upto $25 for each friend your refer!{" "}
              <a href="#invite" onClick={(ev) => ev.preventDefault()}>
                Invite friends
              </a>
            </p>
          </div>
        </ModalFooter> */}
      </Modal>

      <Modal
        isOpen={isOpenPayment}
        toggle={(e) => {
          e.preventDefault();
          togglePayment();
        }}
        size="lg"
      >
        <ModalBody className="modal-body-lg text-center">
          <div className="nk-modal">
            <h4 className="nk-modal-title">Pagamento</h4>
            {renderPlans()}
            <div className="nk-modal-text" style={{ marginTop: 20 }}>
              <div className="caption-text">
                Escolha o método de pagamento e a quantidade de parcelas
              </div>
              {/* <span className="sub-text-sm">
                          Learn when you reciveve bitcoin in your wallet.{" "}
                          <a href="#link" onClick={(ev) => ev.preventDefault()}>
                            {" "}
                            Click here
                          </a>
                        </span> */}
            </div>
            <form
              className="is-alter"
              name="form-contract"
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <Row>
                <Col md="4" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <Label htmlFor="default-4" className="form-label">
                      Tipo de Pagamento
                    </Label>
                    <div className="form-control-wrap">
                      <div className="form-control-select">
                        <RSelect
                          name="payment_type"
                          onChange={(val) =>
                            setSelectsData({
                              ...selectsData,
                              payment_type: val.value,
                            })
                          }
                          placeholder="Método de pagamento"
                          options={[
                            { value: "pix", label: "Pix" },
                            { value: "debito", label: "Débito" },
                            { value: "credito", label: "Crédito" },
                            { value: "boleto", label: "Boleto" },
                          ]}
                        />
                      </div>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="4" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <Label htmlFor="default-4" className="form-label">
                      Parcelas
                    </Label>
                    <div className="form-control-wrap">
                      <div className="form-control-select">
                        <RSelect
                          name="installments"
                          onChange={(val) =>
                            setSelectsData({
                              ...selectsData,
                              installments: val.value,
                            })
                          }
                          placeholder="Selecione as parcelas"
                          options={[
                            { value: "1", label: "Á vista" },
                            { value: "2", label: "2x" },
                            { value: "3", label: "3x" },
                            { value: "4", label: "4x" },
                            { value: "5", label: "5x" },
                            { value: "6", label: "6x" },
                            { value: "7", label: "7x" },
                          ]}
                        />
                      </div>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label className="form-label" htmlFor="nationality">
                      Dia de vencimento
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="number"
                        id="due_date"
                        maxLength={2}
                        ref={register({ required: true })}
                        name="due_date"
                        placeholder="Dia ex: 01"
                        className="form-control-lg form-control"
                      />
                      {errors.due_date && (
                        <p className="invalid">Esse campo é obrigatório.</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>

              <div className="nk-modal-action">
                <Button
                  color="gray"
                  size="lg"
                  className="btn-mw mr-20"
                  style={{ marginRight: 20 }}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setIsOpenPayment(false);
                  }}
                >
                  Voltar
                </Button>
                <Button
                  color="primary"
                  size="lg"
                  type="submit"
                  className="btn-mw"
                >
                  {loading ? <Spinner size="sm" color="light" /> : "Cadastrar"}
                </Button>
              </div>
            </form>
          </div>
        </ModalBody>
        <ModalFooter className="bg-light">
          <div className="text-center w-100">
            <p>Você poderá alterar essas informações quando quiser.</p>
          </div>
        </ModalFooter>
      </Modal>
      <Head title="Cadastro" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body wide-lg">
          <BackTo link="/pacientes" icon="arrow-left">
            Voltar
          </BackTo>
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img
                className="logo-dark logo-img logo-img-lg"
                src={Logo}
                alt="logo"
              />
            </Link>
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Cadastro</BlockTitle>
                <BlockDes>
                  <p>Adicionar novo paciente</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            <form
              name="patient_form"
              onSubmit={(ev) => {
                ev.preventDefault();
                setIsOpenPayment(true);
              }}
            >
              <div className="text-center pt-4 pb-3">
                <h6 className="overline-title overline-title-sap">
                  <span>Dados do paciente</span>
                </h6>
              </div>
              <div className="text-center" style={{}}>
                <div
                  onClick={() => setIsOpen(true)}
                  style={{
                    width: 140,
                    cursor: "pointer",
                    height: 140,
                    margin: "0 auto",
                    backgroundImage: `url("${avatar}")`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    borderRadius: 200,
                  }}
                />
                <Button
                  onClick={(e) => {
                    setIsOpen(true);
                    e.preventDefault();
                  }}
                  className="btn-round btn-icon"
                  color="primary"
                  style={{
                    marginTop: 15,
                    boxShadow: "10px 10px 45px -28px rgba(122,122,122,1)",
                  }}
                  size="lg"
                >
                  <Icon name="camera" />
                </Button>
                <Row>
                  <Col md="12" style={{ marginTop: 20 }}>
                    <span>
                      Plano atual
                      <br />
                    </span>
                    {renderPlans()}
                  </Col>
                </Row>
              </div>
              <Row>
                <Col md="2" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <Label htmlFor="default-4" className="form-label">
                      Plano
                    </Label>
                    <div className="form-control-wrap">
                      <div className="form-control-select">
                        <RSelect
                          name="plan"
                          onChange={(val) =>
                            setSelectsData({
                              ...selectsData,
                              plan: val.value,
                            })
                          }
                          placeholder="Selecione"
                          options={[
                            { value: "essencial", label: "Essencial" },
                            { value: "premium", label: "Premium" },
                            { value: "master", label: "Master" },
                            { value: "platinum", label: "Platinum" },
                          ]}
                        />
                      </div>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="6" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <label className="form-label" htmlFor="name">
                      Nome
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Nome do paciente"
                        ref={register({ required: true })}
                        className="form-control-lg form-control"
                      />
                      {errors.name && (
                        <p className="invalid">Esse campo é obrigatório.</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="4" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <label className="form-label">Data de aniversário</label>
                    <div className="form-control-wrap">
                      <Controller
                        control={control}
                        name="birthday"
                        defaultValue=""
                        render={({ field }) => (
                          <DatePicker
                            selected={birthday}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/YYYY"
                            name="birthday"
                            customInput={
                              <MaskedInput
                                onChange={setBirthday}
                                name="birthday"
                                className="form-control-lg form-control"
                                mask={[
                                  /\d/,
                                  /\d/,
                                  "/",
                                  /\d/,
                                  /\d/,
                                  "/",
                                  /\d/,
                                  /\d/,
                                  /\d/,

                                  /\d/,
                                ]}
                              />
                            }
                            locale={"ptBR"}
                            onChange={setBirthday}
                            className="form-control date-picker"
                          />
                        )}
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col md="4" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <label className="form-label" htmlFor="nationality">
                      Nacionalidade
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="nationality"
                        ref={register({ required: false })}
                        name="nationality"
                        placeholder="Nacionalidade do paciente"
                        className="form-control-lg form-control"
                      />
                      {errors.nationality && (
                        <p className="invalid">Esse campo é obrigatório.</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="2" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <Label htmlFor="default-4" className="form-label">
                      Estado civíl
                    </Label>
                    <div className="form-control-wrap">
                      <div className="form-control-select">
                        <RSelect
                          name="civil_state"
                          onChange={(val) =>
                            setSelectsData({
                              ...selectsData,
                              civil_state: val.value,
                            })
                          }
                          placeholder="Selecione"
                          options={[
                            { value: "casado", label: "Casado" },
                            { value: "solteiro", label: "Solteiro" },
                            { value: "divorciado", label: "Divorciado" },
                          ]}
                          id="default-4"
                        />
                      </div>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="3" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <label className="form-label" htmlFor="rg">
                      RG
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="rg"
                        name="rg"
                        ref={register({ required: false })}
                        placeholder="RG do paciente"
                        className="form-control-lg form-control"
                      />
                      {errors.rg && (
                        <p className="invalid">Esse campo é obrigatório.</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="3" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <label className="form-label" htmlFor="cpf">
                      CPF
                    </label>
                    <div className="form-control-wrap">
                      <MaskedInput
                        id="cpf"
                        name="cpf"
                        onChange={(e) => addCpfs(e.target.value, "patient")}
                        placeholder="CPF do paciente"
                        className="form-control-lg form-control"
                        mask={[
                          /\d/,
                          /\d/,
                          /\d/,
                          ".",
                          /\d/,
                          /\d/,
                          /\d/,
                          ".",
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                        ]}
                      />
                      {errors.cpf && (
                        <p className="invalid">Esse campo é obrigatório.</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="4" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <label className="form-label" htmlFor="cep">
                      CEP
                    </label>
                    <div className="form-control-wrap">
                      <MaskedInput
                        id="cep"
                        onChange={(e) => addCeps(e.target.value, "patient")}
                        onBlur={(val) => fillCEP(val.target.value, "patient")}
                        name="cep"
                        placeholder="CEP"
                        className="form-control-lg form-control"
                        mask={[
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                      />
                      {errors.cep && (
                        <p className="invalid">Esse campo é obrigatório.</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="6" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <label className="form-label" htmlFor="address">
                      Logradouro
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="address"
                        name="address"
                        ref={register({ required: false })}
                        placeholder="Endereço do paciente"
                        className="form-control-lg form-control"
                      />
                      {errors.address && (
                        <p className="invalid">Esse campo é obrigatório.</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="2" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <label className="form-label" htmlFor="number">
                      Número
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="number"
                        name="number"
                        placeholder="Número"
                        ref={register({ required: false })}
                        className="form-control-lg form-control"
                      />
                      {errors.number && (
                        <p className="invalid">Esse campo é obrigatório.</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="3" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <label className="form-label" htmlFor="address_complement">
                      Complemento
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="address_complement"
                        name="address_complement"
                        placeholder="Complemento"
                        ref={register({ required: false })}
                        className="form-control-lg form-control"
                      />
                      {errors.address_complement && (
                        <p className="invalid">Esse campo é obrigatório.</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="3" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <label
                      className="form-label"
                      htmlFor="address_neighborhood"
                    >
                      Bairro
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="address_neighborhood"
                        name="address_neighborhood"
                        placeholder="Bairro"
                        ref={register({ required: false })}
                        className="form-control-lg form-control"
                      />
                      {errors.address_neighborhood && (
                        <p className="invalid">Esse campo é obrigatório.</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>

                <Col md="4" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <label className="form-label" htmlFor="city">
                      Cidade
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="city"
                        name="city"
                        ref={register({ required: false })}
                        placeholder="Cidade do paciente"
                        className="form-control-lg form-control"
                      />
                      {errors.city && (
                        <p className="invalid">Esse campo é obrigatório.</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="2" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <label className="form-label" htmlFor="state">
                      Estado
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="state"
                        name="state"
                        placeholder="Estado"
                        className="form-control-lg form-control"
                        ref={register({ required: false })}
                      />
                      {errors.state && (
                        <p className="invalid">Esse campo é obrigatório.</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col sm={7}>
                  <FormGroup>
                    <label className="form-label">Período do tratamento</label>{" "}
                    <div className="form-control-wrap">
                      <div className="input-daterange date-picker-range input-group">
                        <Controller
                          control={control}
                          name="rangeStart"
                          defaultValue=""
                          render={({ field }) => (
                            <DatePicker
                              selected={rangeStart}
                              onChange={setRangeStart}
                              selectsStart
                              dateFormat="dd/MM/yyyy"
                              placeholderText="DD/MM/YYYY"
                              startDate={rangeStart}
                              endDate={rangeEnd}
                              name="rangeStart"
                              customInput={
                                <MaskedInput
                                  onChange={setRangeStart}
                                  name="rangeStart"
                                  className="form-control-lg form-control"
                                  mask={[
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                    /\d/,

                                    /\d/,
                                  ]}
                                />
                              }
                              locale={"ptBR"}
                              wrapperClassName="start-m"
                              scrollableMonthYearDropdown
                              className="form-control"
                            />
                          )}
                        />
                        <div className="input-group-addon">ATÉ</div>
                        <Controller
                          control={control}
                          name="rangeEnd"
                          defaultValue=""
                          render={({ field }) => (
                            <DatePicker
                              selected={rangeEnd}
                              placeholderText="DD/MM/YYYY"
                              name="rangeEnd"
                              customInput={
                                <MaskedInput
                                  onChange={setRangeEnd}
                                  name="rangeEnd"
                                  className="form-control-lg form-control"
                                  mask={[
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                    /\d/,

                                    /\d/,
                                  ]}
                                />
                              }
                              onChange={setRangeEnd}
                              startDate={rangeStart}
                              scrollableMonthYearDropdown
                              endDate={rangeEnd}
                              selectsEnd
                              dateFormat="dd/MM/yyyy"
                              minDate={rangeStart}
                              locale={"ptBR"}
                              wrapperClassName="end-m"
                              className="form-control"
                            />
                          )}
                        />
                      </div>
                    </div>
                  </FormGroup>
                </Col>
              </Row>

              <div className="text-center pt-4 pb-3">
                <h6 className="overline-title overline-title-sap">
                  <span>Dados do responsável</span>
                </h6>
              </div>

              <Row>
                <Col md="12" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <Label htmlFor="default-4" className="form-label">
                      Tipo de vínculo
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="patient_link"
                        ref={register({ required: false })}
                        name="patient_link"
                        placeholder="Ex: Pai; Mãe; Avó; Avô; Marido; Esposa..."
                        className="form-control-lg form-control"
                      />
                      {errors.patient_link && (
                        <p className="invalid">Esse campo é obrigatório.</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="8" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <label className="form-label" htmlFor="name_responsable">
                      Nome
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="name_responsable"
                        name="name_responsable"
                        ref={register({ required: false })}
                        placeholder="Nome do responsável"
                        className="form-control-lg form-control"
                      />
                      {errors.name_responsable && (
                        <p className="invalid">Esse campo é obrigatório.</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="4" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <label
                      className="form-label"
                      htmlFor="nationality_responsable"
                    >
                      Nacionalidade
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="nationality_responsable"
                        name="nationality_responsable"
                        ref={register({ required: false })}
                        placeholder="Nacionalidade do responsável"
                        className="form-control-lg form-control"
                      />
                      {errors.nationality_responsable && (
                        <p className="invalid">Esse campo é obrigatório.</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="4" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <Label htmlFor="default-4" className="form-label">
                      Estado civíl
                    </Label>
                    <div className="form-control-wrap">
                      <div className="form-control-select">
                        <RSelect
                          name="civil_state_responsable"
                          onChange={(val) =>
                            setSelectsData({
                              ...selectsData,
                              civil_state_responsable: val.value,
                            })
                          }
                          placeholder="Selecione"
                          options={[
                            { value: "casado", label: "Casado" },
                            { value: "solteiro", label: "Solteiro" },
                            { value: "divorciado", label: "Divorciado" },
                          ]}
                        />
                      </div>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="4" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <label className="form-label" htmlFor="rg_responsable">
                      RG
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        ref={register({ required: false })}
                        id="rg_responsable"
                        name="rg_responsable"
                        placeholder="RG do responsável"
                        className="form-control-lg form-control"
                      />
                      {errors.rg_responsable && (
                        <p className="invalid">Esse campo é obrigatório.</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="4" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <label className="form-label" htmlFor="cpf_responsable">
                      CPF
                    </label>
                    <div className="form-control-wrap">
                      <MaskedInput
                        id="cpf_responsable"
                        name="cpf_responsable"
                        placeholder="CPF do responsável"
                        onChange={(e) => addCpfs(e.target.value, "responsable")}
                        className="form-control-lg form-control"
                        mask={[
                          /\d/,
                          /\d/,
                          /\d/,
                          ".",
                          /\d/,
                          /\d/,
                          /\d/,
                          ".",
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                        ]}
                      />
                      {errors.cpf && (
                        <p className="invalid">Esse campo é obrigatório.</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="4" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <label className="form-label" htmlFor="cep_responsable">
                      CEP
                    </label>
                    <div className="form-control-wrap">
                      <MaskedInput
                        id="cep_responsable"
                        name="cep_responsable"
                        placeholder="CEP"
                        onBlur={(val) =>
                          fillCEP(val.target.value, "responsable")
                        }
                        onChange={(e) => addCeps(e.target.value, "responsable")}
                        className="form-control-lg form-control"
                        mask={[
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                      />
                      {errors.cep_responsable && (
                        <p className="invalid">Esse campo é obrigatório.</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="6" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <label className="form-label" htmlFor="address_responsable">
                      Logradouro
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="address_responsable"
                        name="address_responsable"
                        ref={register({ required: false })}
                        placeholder="Endereço do paciente"
                        className="form-control-lg form-control"
                      />
                      {errors.address_responsable && (
                        <p className="invalid">Esse campo é obrigatório.</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="2" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <label
                      className="form-label"
                      htmlFor="address_number_responsable"
                    >
                      Número
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="address_number_responsable"
                        name="address_number_responsable"
                        ref={register({ required: false })}
                        placeholder="Número"
                        className="form-control-lg form-control"
                      />
                      {errors.address_number_responsable && (
                        <p className="invalid">Esse campo é obrigatório.</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="3" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <label
                      className="form-label"
                      htmlFor="address_complement_responsable"
                    >
                      Complemento
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="address_complement_responsable"
                        name="address_complement_responsable"
                        ref={register({ required: false })}
                        placeholder="Complemento"
                        className="form-control-lg form-control"
                      />
                      {errors.address_complement && (
                        <p className="invalid">Esse campo é obrigatório.</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="3" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <label
                      className="form-label"
                      htmlFor="address_neighborhood_responsable"
                    >
                      Bairro
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="address_neighborhood_responsable"
                        name="address_neighborhood_responsable"
                        ref={register({ required: false })}
                        placeholder="Bairro"
                        className="form-control-lg form-control"
                      />
                      {errors.address_neighborhood_responsable && (
                        <p className="invalid">Esse campo é obrigatório.</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>

                <Col md="3" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <label className="form-label" htmlFor="state_responsable">
                      Estado
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="state_responsable"
                        name="state_responsable"
                        placeholder="Estado"
                        ref={register({ required: false })}
                        className="form-control-lg form-control"
                      />
                      {errors.state_responsable && (
                        <p className="invalid">Esse campo é obrigatório.</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="3" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <label className="form-label" htmlFor="city_responsable">
                      Cidade
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="city_responsable"
                        name="city_responsable"
                        ref={register({ required: false })}
                        placeholder="Cidade do responsável"
                        className="form-control-lg form-control"
                      />
                      {errors.city_responsable && (
                        <p className="invalid">Esse campo é obrigatório.</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="6" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <label className="form-label" htmlFor="phone_responsable">
                      Telefone
                    </label>
                    {phones.map((phone, index) => {
                      return (
                        <Row style={{ marginBottom: 15 }}>
                          <Col md={5}>
                            <div className="form-control-wrap">
                              <MaskedInput
                                type="text"
                                id="phone_responsable"
                                onChange={(e) => {
                                  let newArr = phones.map((item, i) => {
                                    if (index === i) {
                                      return { ...item, phone: e.target.value };
                                    } else {
                                      return item;
                                    }
                                  });
                                  setPhones(newArr);
                                }}
                                value={phone.phone}
                                name="phone_responsable"
                                placeholder="Telefone"
                                className="form-control-lg form-control"
                                mask={[
                                  "(",
                                  /[1-9]/,
                                  /\d/,
                                  ")",
                                  " ",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  "-",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                ]}
                              />
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                id="phone_responsable_name"
                                name="phone_responsable_name"
                                onChange={(e) => {
                                  let newArr = phones.map((item, i) => {
                                    if (index === i) {
                                      return { ...item, name: e.target.value };
                                    } else {
                                      return item;
                                    }
                                  });
                                  setPhones(newArr);
                                }}
                                value={phone.name}
                                placeholder="Responsável"
                                ref={register({ required: false })}
                                className="form-control-lg form-control"
                              />
                            </div>
                          </Col>
                          <Col md={2}>
                            {index === phones.length - 1 ? (
                              <Button
                                onClick={(e) => {
                                  e.preventDefault();
                                  addPhone();
                                }}
                                className="btn-round btn-icon"
                                color="primary"
                                size="sm"
                              >
                                <Icon name="plus" />
                              </Button>
                            ) : (
                              <Button
                                onClick={(e) => {
                                  e.preventDefault();
                                  let p = phones.filter((r, ri) => {
                                    return index !== ri;
                                  });
                                  setPhones(p);
                                }}
                                className="btn-round btn-icon"
                                color="primary"
                                size="sm"
                              >
                                <Icon name="cross" />
                              </Button>
                            )}
                          </Col>
                        </Row>
                      );
                    })}
                  </FormGroup>
                </Col>
                <Col md="6" style={{ marginBottom: 20 }}>
                  <FormGroup>
                    <label className="form-label" htmlFor="email_responsable">
                      E-mail do responsável
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="email"
                        id="email_responsable"
                        name="email_responsable"
                        placeholder="E-mail do responsável"
                        ref={register({ required: false })}
                        className="form-control-lg form-control"
                      />
                      {errors.email_responsable && (
                        <p className="invalid">Esse campo é obrigatório.</p>
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <Button
                  color="primary"
                  size="lg"
                  style={{
                    marginTop: 20,
                  }}
                  className="btn-block"
                >
                  Avançar
                </Button>
              </FormGroup>

              {/* <div className="text-center pt-4 pb-3">
              <h6 className="overline-title overline-title-sap">
                <span>OR</span>
              </h6>
            </div> */}
            </form>
          </PreviewCard>
        </Block>
      </PageContainer>
    </React.Fragment>
  );
};
export default RegisterPatient;
