import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";

import { saveAs } from "file-saver";

const ExportPatientsSign = () => {
  const [data, setData] = useState([]);
  const getPatients = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/patients?populate=*&pagination[pageSize]=99999&sort[0]=contracts.start_date:desc`
      )
      .then((response) => {
        const patients = response.data.data.filter((item) => {
          return item.attributes.contracts.data.length > 0;
        });
        setTimeout(() => {
          window.print();
        }, 100);
        setData(patients);
      });
  };

  const renderDate = (item) => {
    const date = moment(new Date()).diff(
      item.attributes.contracts.data[0].attributes.start_date
    );
    const years = moment.duration(date).years()
      ? `${moment.duration(date).years()}${
          moment.duration(date).years() > 1 ? " anos" : " ano"
        }`
      : "";
    const months = moment.duration(date).months()
      ? `${moment.duration(date).months()}${
          moment.duration(date).months() > 1 ? " meses" : " mês"
        }`
      : "";
    const days = moment.duration(date).days()
      ? `${moment.duration(date).days()}${
          moment.duration(date).days() > 1 ? " dias" : " dia"
        }`
      : "";
    return `${years} ${months} ${months > "0" && days ? " e " + days : days}`;
  };

  const filterActive = () => {
    return data.filter(
      (item) => item.attributes.contracts.data[0].attributes.is_active
    ).length;
  };

  const formataCPF = (cpf) => {
    cpf = cpf.replace(/[^\d]/g, "");
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  };

  useEffect(async () => {
    await getPatients();
  }, []);

  return (
    <React.Fragment>
      {/* <h2 style={{ textAlign: "center", padding: 20 }}>
        Lista de pacientes com plano ({filterActive()})
      </h2> */}
      <div style={{ textAlign: "center" }}>
        <table style={{ margin: "0 auto", marginBottom: 30, width: "100%" }}>
          <thead>
            <tr>
              {/* <th style={{ padding: 15, border: "1px solid #000000" }}>
                Código
              </th> */}
              <th
                style={{ padding: 15, border: "1px solid #000000", width: 380 }}
              >
                Nome do paciente
              </th>
              {/* <th style={{ padding: 15, border: "1px solid #000000" }}>
                Data de Nascimento
              </th> */}
              {/* <th style={{ padding: 15, border: "1px solid #000000" }}>CPF</th> */}
              <th style={{ padding: 15, border: "1px solid #000000" }}>
                Tempo corrido
              </th>
              <th style={{ padding: 15, border: "1px solid #000000" }}>
                Data de entrada
              </th>
              {/* <th style={{ padding: 15, border: "1px solid #000000" }}>
                Data de saída
              </th> */}
              <th style={{ padding: 15, border: "1px solid #000000" }}>Observações ou Assinatura</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => {
              if (item.attributes.contracts.data[0].attributes.is_active) {
                return (
                  <tr
                    key={item.attributes.code}
                    style={{ border: "1px solid #000000" }}
                  >
                    {/* <td
                      style={{
                        padding: 15,
                        paddingBottom: 5,
                        paddingTop: 5,
                        border: "1px solid #000000",
                      }}
                    >
                      {item.attributes.code}
                    </td> */}
                    <td
                      style={{
                        padding: 15,
                        paddingBottom: 5,
                        paddingTop: 5,
                        border: "1px solid #000000",
                      }}
                    >
                      {item.attributes.name}
                    </td>
                    {/* <td
                      style={{
                        padding: 15,
                        paddingBottom: 5,
                        paddingTop: 5,
                        border: "1px solid #000000",
                      }}
                    >
                      {moment(item.attributes.birthday).format("DD/MM/YYYY")}
                    </td> */}
                    {/* <td
                      style={{
                        padding: 15,
                        paddingBottom: 5,
                        paddingTop: 5,
                        border: "1px solid #000000",
                      }}
                    >
                      {formataCPF(item.attributes.cpf)}
                    </td> */}
                    <td
                      style={{
                        padding: 15,
                        paddingBottom: 5,
                        paddingTop: 5,
                        border: "1px solid #000000",
                      }}
                    >
                      {renderDate(item)}
                    </td>
                    <td
                      style={{
                        padding: 15,
                        paddingBottom: 5,
                        paddingTop: 5,
                        border: "1px solid #000000",
                      }}
                    >
                      {moment(
                        item.attributes.contracts.data[0].attributes.start_date
                      ).format("DD/MM/YYYY")}
                    </td>
                    <td
                      style={{
                        padding: 15,
                        paddingBottom: 5,
                        paddingTop: 5,
                        width: 300,
                        border: "1px solid #000000",
                      }}
                    >
                      {/* {moment(
                        item.attributes.contracts.data[0].attributes.end_date
                      ).format("DD/MM/YYYY")} */}
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default ExportPatientsSign;
