    import React, { useContext, useEffect, useState } from "react";
    import axios from "axios";
    import moment from "moment";
    import {
      Block,
      BlockBetween,
      BlockDes,
      BlockHead,
      BlockHeadContent,
      BlockTitle,
      Icon,
      RSelect,
    } from "../../../components/Component";
    import { Row, Col, Button, ButtonGroup, ButtonToolbar } from "reactstrap";

    import { saveAs } from "file-saver";

    const ExportPatients = () => {
      const [data, setData] = useState([]);
      const [colActive, setColActive] = useState(['code', 'cpf', 'entrada', 'saida', 'corrido', 'nascimento', 'contrato']);

      const getPatients = async () => {
        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/api/patients?populate=*&pagination[pageSize]=99999&sort[0]=contracts.start_date:desc`
          )
          .then((response) => {
            const patients = response.data.data.filter((item) => {
              return item.attributes.contracts.data.length > 0;
            });
            setData(patients);
          });
      };

      const renderDate = (item) => {
        const date = moment(new Date()).diff(
          item.attributes.contracts.data[0].attributes.start_date
        );
        const years = moment.duration(date).years()
          ? `${moment.duration(date).years()}${
              moment.duration(date).years() > 1 ? " anos" : " ano"
            }`
          : "";
        const months = moment.duration(date).months()
          ? `${moment.duration(date).months()}${
              moment.duration(date).months() > 1 ? " meses" : " mês"
            }`
          : "";
        const days = moment.duration(date).days()
          ? `${moment.duration(date).days()}${
              moment.duration(date).days() > 1 ? " dias" : " dia"
            }`
          : "";
        return `${years} ${months} ${months > "0" && days ? " e " + days : days}`;
      };

      const filterActive = () => {
        return data.filter(
          (item) => item.attributes.contracts.data[0]?.attributes.is_active || item.attributes.contracts.data[1]?.attributes.is_active || item.attributes.contracts.data[2]?.attributes.is_active
        ).length;
      };

      const formataCPF = (cpf) => {
        cpf = cpf.replace(/[^\d]/g, "");
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      };
      

      const checkIsActiveCol = (col) => {
        return colActive.includes(col)
      }

      const toggleCol = (col) => {
        if(colActive.includes(col)) {
          let removed = colActive.filter(item => item !== col)
          setColActive(removed)
        } else {
          setColActive([...colActive, col])
        }
      }

      useEffect(async () => {
        await getPatients();
      }, []);

      return (
        <React.Fragment>
          <h2 style={{ textAlign: "center", padding: 20 }}>
            Lista de pacientes com plano ({filterActive()})
          </h2>
          <Row className="print_btn">
            <Col md={2} style={{ margin: 20 }}>
            Ocultar colunas
            <ButtonGroup size="sm">
            <Button active={checkIsActiveCol('code')} color="primary" onClick={() => toggleCol('code') } style={{ minWidth: 150, textAlign: 'center'}}>Código</Button>
            <Button active={checkIsActiveCol('nascimento')} color="primary" onClick={() => toggleCol('nascimento') } style={{ minWidth: 150, textAlign: 'center'}}>Data de Nascimento</Button>
            <Button active={checkIsActiveCol('cpf')} color="primary" onClick={() => toggleCol('cpf') } style={{ minWidth: 150, textAlign: 'center'}}>CPF</Button>
            <Button active={checkIsActiveCol('corrido')} color="primary" onClick={() => toggleCol('corrido') } style={{ minWidth: 150, textAlign: 'center'}}>Tempo Corrido</Button>
            <Button active={checkIsActiveCol('entrada')} color="primary" onClick={() => toggleCol('entrada') } style={{ minWidth: 150, textAlign: 'center'}}>Data de entrada</Button>
            <Button active={checkIsActiveCol('saida')} color="primary" onClick={() => toggleCol('saida') } style={{ minWidth: 150, textAlign: 'center'}}>Data de saída</Button>
            <Button active={checkIsActiveCol('contrato')} color="primary" onClick={() => toggleCol('contrato') } style={{ minWidth: 150, textAlign: 'center'}}>Tempo de Contrato</Button>

          </ButtonGroup>
            </Col>
          </Row>
          <div style={{ textAlign: "center" }}>
            <table style={{ margin: "0 auto", marginBottom: 30, width: "100%" }}>
              <thead>
                <tr>
                  {checkIsActiveCol('code') && (
                    <th style={{ padding: 15, border: "1px solid #000000" }}>
                      Código
                    </th>
                  )}
                  <th
                    style={{ padding: 15, border: "1px solid #000000", width: 380 }}
                  >
                    Nome do paciente
                  </th>
                  {checkIsActiveCol('nascimento') && (
                  <th style={{ padding: 15, border: "1px solid #000000" }}>
                    Data de Nascimento
                  </th>
                  )}
                  {checkIsActiveCol('cpf') && (
                  <th style={{ padding: 15, border: "1px solid #000000" }}>CPF</th>
                  )}
                                  {checkIsActiveCol('corrido') && (

                  <th style={{ padding: 15, border: "1px solid #000000" }}>
                    Tempo corrido
                  </th>
                                  )}
                    {checkIsActiveCol('entrada') && (
                  <th style={{ padding: 15, border: "1px solid #000000" }}>
                    Data de entrada
                  </th>
                    )}
                    {checkIsActiveCol('saida') && (
                  <th style={{ padding: 15, border: "1px solid #000000"}}>
                    Data de saída
                  </th>
                    )}
                    {checkIsActiveCol('contrato') && (
                  <th style={{ padding: 15, border: "1px solid #000000"}}>
                    Tempo de contrato
                  </th>
                    )}
                </tr>
              </thead>
              <tbody>
                {data.map((item) => {
                  if (item.attributes.contracts.data[0]?.attributes.is_active || item.attributes.contracts.data[1]?.attributes.is_active || item.attributes.contracts.data[2]?.attributes.is_active) {
                    return (
                      <tr
                        key={item.attributes.code}
                        style={{ border: "1px solid #000000" }}
                      >
                                      {checkIsActiveCol('code') && (

                        <td
                          style={{
                            padding: 15,
                            paddingBottom: 5,
                            paddingTop: 5,
                            border: "1px solid #000000",
                          }}
                        >
                          
                          {item.attributes.code}
                        </td>
                        )}
                        <td
                          style={{
                            padding: 15,
                            paddingBottom: 5,
                            paddingTop: 5,
                            border: "1px solid #000000",
                          }}
                        >
                          {item.attributes.name}
                        </td>
                        {checkIsActiveCol('nascimento') && (

                        <td
                          style={{
                            padding: 15,
                            paddingBottom: 5,
                            paddingTop: 5,
                            border: "1px solid #000000",
                          }}
                        >
                          {moment(item.attributes.birthday).format("DD/MM/YYYY")}
                        </td>
                        )}
                                          {checkIsActiveCol('cpf') && (

                        <td
                          style={{
                            padding: 15,
                            paddingBottom: 5,
                            paddingTop: 5,
                            border: "1px solid #000000",
                          }}
                        >
                          {formataCPF(item.attributes.cpf)}
                        </td>
                                          )}
                                                            {checkIsActiveCol('corrido') && (

                        <td
                          style={{
                            padding: 15,
                            paddingBottom: 5,
                            paddingTop: 5,
                            border: "1px solid #000000",
                          }}
                        >
                          {renderDate(item)}
                        </td>
                                                            )}
                                                                              {checkIsActiveCol('entrada') && (

                        <td
                          style={{
                            padding: 15,
                            paddingBottom: 5,
                            paddingTop: 5,
                            border: "1px solid #000000",
                          }}
                        >
                          {moment(
                            item.attributes.contracts.data[0].attributes.start_date
                          ).format("DD/MM/YYYY")}
                        </td>
                                                                              )}
                                                                                                {checkIsActiveCol('saida') && (

                        <td
                          style={{
                            padding: 15,
                            paddingBottom: 5,
                            paddingTop: 5,
                            border: "1px solid #000000",
                          }}
                        >
                          {moment(
                            item.attributes.contracts.data[0].attributes.end_date
                          ).format("DD/MM/YYYY")}
                        </td>
                                                                                                )}
{checkIsActiveCol('contrato') && (
<td
                          style={{
                            padding: 15,
                            paddingBottom: 5,
                            paddingTop: 5,
                            border: "1px solid #000000",
                          }}
                        >
                          {moment(
                              item.attributes.contracts.data[0].attributes
                                .end_date
                            ).diff(
                              moment(
                                item.attributes.contracts.data[0].attributes
                                  .start_date
                              ),
                              "months"
                            )}{" "}
                            meses
                        </td>
)}                                                                 
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </div>
        </React.Fragment>
      );
    };
    export default ExportPatients;
