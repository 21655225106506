import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import {
  Button,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Block,
  BlockContent,
  PreviewCard,
  RSelect,
  BackTo,
} from "../../../components/Component";
import { useHistory } from "react-router";
import Content from "../../../layout/content/Content";
import {
  currentTime,
  findUpper,
  monthNames,
  todaysDate,
} from "../../../utils/Utils";
import moment from "moment";
import { UserContext } from "../../pre-built/user-manage/UserContext";
import { notes } from "../../pre-built/user-manage/UserData";
import Webcam from "react-webcam";
import Logo from "../../../images/logo.png";
import Avatar from "../../../images/avatar/avatar.png";
import MaskedInput from "react-text-mask";
import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import {
  Spinner,
  FormGroup,
  Label,
  Row,
  Col,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Badge,
} from "reactstrap";
import PageContainer from "../../../layout/page-container/PageContainer";
import Head from "../../../layout/head/Head";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { getRandomPin } from "../../../utils/Utils";

const UserDetailsPage = ({ match }) => {
  const { contextData } = useContext(UserContext);
  const [data] = contextData;

  const [avatar, setAvatar] = useState(Avatar);
  const [loading, setLoading] = useState(false);
  const [selectsData, setSelectsData] = useState({});
  const { control, errors, register, handleSubmit, setValue, reset } =
    useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPayment, setIsOpenPayment] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const webcamRef = useRef(null);
  const [rangeStart, setRangeStart] = useState(new Date());
  const [rangeEnd, setRangeEnd] = useState();
  const [birthday, setBirthday] = useState();
  const [isActive, setIsActive] = useState(false);
  const [patientId, setPatientId] = useState();

  const [ceps, setCeps] = useState({
    cep: "",
    cep_responsable: "",
  });

  const [cpfs, setCpfs] = useState({
    cpf: "",
    cpf_responsable: "",
  });

  const [phones, setPhones] = useState([
    {
      phone: "",
      name: "",
    },
  ]);

  registerLocale("ptBR", ptBR);
  const plansOptions = [
    {
      value: "essencial",
      label: "Essencial",
    },
    { value: "premium", label: "Premium" },
    { value: "master", label: "Master" },
    { value: "platinum", label: "Platinum" },
  ];

  const civilStateOptions = [
    { value: "casado", label: "Casado" },
    { value: "solteiro", label: "Solteiro" },
    {
      value: "divorciado",
      label: "Divorciado",
    },
    {
      value: "viuvo",
      label: "Viúvo",
    },
  ];
  const [planSelected, setPlanSelected] = useState();
  const getUser = async () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/patients/${match.params.id}?populate=*&pagination[pageSize]=99999`
      )
      .then((resp) => {
        const dataFill = {
          ...resp.data.data.attributes,
          ...resp.data.data.attributes.contracts.data[0].attributes,
          code: resp.data.data.attributes.code,
          contract_id:
            resp.data.data.attributes.contracts.data[0].id,
        };
        setIsActive(resp.data.data.attributes.contracts.data[0].attributes.is_active)
        setSelectsData({
          civil_state: dataFill.civil_state,
          civil_state_responsable: dataFill.civil_state_responsable,
          plan: dataFill.plan,
        });
        setAvatar(dataFill.avatar_base64);
        setCeps({
          cep: dataFill.cep,
          cep_responsable: dataFill.cep_responsable,
        });
        setCpfs({
          cpf: dataFill.cpf,
          cpf_responsable: dataFill.cpf_responsable,
        });
        setRangeStart(new Date(dataFill.start_date.replace(/-/g, "/")))
        
        setRangeEnd(new Date(dataFill.end_date.replace(/-/g, "/")))

        setPhones(dataFill.phones);

        setUser(dataFill);

        setBirthday(new Date(dataFill.birthday.replace(/-/g, "/")));

        setPlanSelected(
          plansOptions.filter((v) => v.value === dataFill.plan)[0]
        );

        setTimeout(() => {
          for (let i in dataFill) {
            setValue(`${i}`, dataFill[i]);
          }
        }, 100);
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  const fillCEP = (cep, where) => {
    axios
      .get(`${process.env.REACT_APP_PROTOCOL}://viacep.com.br/ws/${cep}/json/`)
      .then((data) => {
        if (where === "patient") {
          setValue("address", data.data.logradouro);
          setValue("city", data.data.localidade);
          setValue("address_neighborhood", data.data.bairro);
          setValue("state", data.data.uf);
        } else {
          setValue("address_responsable", data.data.logradouro);
          setValue("city_responsable", data.data.localidade);
          setValue("address_neighborhood_responsable", data.data.bairro);
          setValue("state_responsable", data.data.uf);
        }
      });
  };

  const addCeps = (cep, type) => {
    setCeps({
      ...ceps,
      [type === "patient" ? "cep" : "cep_responsable"]: cep,
    });
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const togglePayment = () => {
    setIsOpenPayment(!isOpenPayment);
  };

  const handleFormSubmit = (data) => {
    setLoading(true);
    axios
      .put(`${process.env.REACT_APP_API_URL}/api/patients/${match.params.id}`, {
        data: {
          ...data,
          ...selectsData,
          phones,
          avatar_base64: avatar,
          start_date: moment(rangeStart).format("YYYY-MM-DD"),
          end_date: moment(rangeEnd).format("YYYY-MM-DD"),
          cpf: cpfs.cpf,
          cpf_responsable: cpfs.cpf_responsable,
          birthday,
          ...ceps,
          code: getRandomPin("123456789", 4),
        },
      })
      .then((response) => {
        setLoading(false);
        saveContract(data, response.data.data.id);
      });
  };

  const saveContract = async (data, id, status) => {
    await axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/contracts/${user.contract_id}`,
        {
          data: {
            ...data,
            ...selectsData,
            phones,
            ...ceps,
            start_date: moment(rangeStart).format("YYYY-MM-DD"),
            end_date: moment(rangeEnd).format("YYYY-MM-DD"),
            cpf: cpfs.cpf,
            cpf_responsable: cpfs.cpf_responsable,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        setIsOpenPayment(false);
        if(!status) {
          setModalSuccess(true);
        }
      });
  };

  const changeStatus = () => {
    setIsActive(!isActive);
    saveContract({ is_active: !isActive }, null, true)
  }

  const addPhone = () => {
    setPhones([
      ...phones,
      {
        phone: null,
        name: null,
      },
    ]);
  };

  const addCpfs = (cpf, type) => {
    setCpfs({
      ...cpfs,
      [type === "patient" ? "cpf" : "cpf_responsable"]: cpf,
    });
  };

  const renderPlans = () => {
    switch (selectsData.plan) {
      case "essencial":
        return (
          <Badge className="badge-md" color="success">
            Essencial
          </Badge>
        );
      case "premium":
        return (
          <Badge className="badge-md" color="primary">
            Premium
          </Badge>
        );

      case "master":
        return (
          <Badge className="badge-md" color="warning">
            Master
          </Badge>
        );

      case "platinum":
        return (
          <Badge className="badge-md" color="gray">
            Platinum
          </Badge>
        );

      default:
        return (
          <Badge className="badge-md" color="light">
            Sem plano definido
          </Badge>
        );
    }
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setAvatar(imageSrc);
    setIsOpen(false);
  }, [webcamRef]);

  const [sideBar, setSidebar] = useState(false);
  const [user, setUser] = useState();
  const [noteData, setNoteData] = useState(notes);
  const [addNoteModal, setAddNoteModal] = useState(false);
  const [addNoteText, setAddNoteText] = useState("");
  const history = useHistory();

  // delete a note
  const deleteNote = (id) => {
    let defaultNote = noteData;
    defaultNote = defaultNote.filter((item) => item.id !== id);
    setNoteData(defaultNote);
  };

  const submitNote = () => {
    let submitData = {
      id: Math.random(),
      text: addNoteText,
      date: `${
        monthNames[todaysDate.getMonth()]
      } ${todaysDate.getDate()}, ${todaysDate.getFullYear()}`,
      time: `${currentTime()}`,
      company: "Softnio",
    };
    setNoteData([...noteData, submitData]);
    setAddNoteModal(false);
    setAddNoteText("");
  };

  return (
    <React.Fragment>
      <Head title={`Paciente`}/>
      {user && (
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  Paciente /{" "}
                  <strong className="text-primary small">{user.name}</strong>
                </BlockTitle>
                <BlockDes className="text-soft">
                  <ul className="list-inline">
                    <li>
                      Identificação do Paciente:{" "}
                      <span className="text-base">{user.code}</span>
                    </li>
                  </ul>
                </BlockDes>
              </BlockHeadContent>

              <BlockHeadContent>
              <Button
                  color={isActive ? "success" : "danger"}
                  outline
                  className="d-none d-sm-inline-flex justify-right mr-1"
                  onClick={() => changeStatus() }
                >
                  { isActive ? "Ativo" : "Suspenso" }
                </Button> 
                <Button
                  color="light"
                  outline
                  className="bg-white d-none d-sm-inline-flex"
                  onClick={() => history.goBack()}
                >
                  <Icon name="arrow-left"></Icon>
                  <span>Voltar</span>
                </Button>
                <a
                  href="#back"
                  onClick={(ev) => {
                    ev.preventDefault();
                    history.goBack();
                  }}
                  className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                >
                  <Icon name="arrow-left"></Icon>
                </a>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <React.Fragment>
            <Modal
              size="lg"
              isOpen={isOpen}
              toggle={(e) => {
                e.preventDefault();
                toggle();
              }}
            >
              <ModalHeader
                toggle={(e) => {
                  e.preventDefault();
                  toggle();
                }}
                close={
                  <button
                    className="close"
                    onClick={(e) => {
                      e.preventDefault();
                      toggle();
                    }}
                  >
                    <Icon name="cross" />
                  </button>
                }
              >
                Capturar foto do paciente
              </ModalHeader>
              <ModalBody>
                <Webcam
                  height={600}
                  width={600}
                  style={{ width: "100%" }}
                  ref={webcamRef}
                />
              </ModalBody>
              <ModalFooter
                style={{
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    capture();
                  }}
                  className="btn-round btn-icon"
                  color="primary"
                  size="lg"
                >
                  <Icon name="camera" />
                </Button>
              </ModalFooter>
            </Modal>

            <Modal isOpen={modalSuccess}>
              <ModalBody className="modal-body-lg text-center">
                <div className="nk-modal">
                  <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-check bg-success"></Icon>
                  <h4 className="nk-modal-title">
                    Paciente adicionado com sucesso
                  </h4>
                  <div className="nk-modal-text">
                    {/* <div className="caption-text">
                          You’ve successfully bought <strong>0.5968</strong> BTC for <strong>200.00</strong> USD
                        </div> */}
                    <span className="sub-text-sm">
                      Você será redirecionado para a listagem de pacientes...
                    </span>
                  </div>
                  <div className="nk-modal-action">
                    <Button
                      color="secondary"
                      size="lg"
                      className="btn-mw"
                      onClick={() => {
                        history.push(`${process.env.PUBLIC_URL}/pacientes`);
                      }}
                    >
                      OK
                    </Button>
                  </div>
                </div>
              </ModalBody>
              {/* <ModalFooter className="bg-light">
          <div className="text-center w-100">
            <p>
              Earn upto $25 for each friend your refer!{" "}
              <a href="#invite" onClick={(ev) => ev.preventDefault()}>
                Invite friends
              </a>
            </p>
          </div>
        </ModalFooter> */}
            </Modal>

            <Modal
              isOpen={isOpenPayment}
              toggle={(e) => {
                e.preventDefault();
                togglePayment();
              }}
              size="lg"
            >
              <ModalBody className="modal-body-lg text-center">
                <div className="nk-modal">
                  <h4 className="nk-modal-title">Pagamento</h4>
                  {renderPlans()}
                  <div className="nk-modal-text" style={{ marginTop: 20 }}>
                    <div className="caption-text">
                      Escolha o método de pagamento e a quantidade de parcelas
                    </div>
                    {/* <span className="sub-text-sm">
                          Learn when you reciveve bitcoin in your wallet.{" "}
                          <a href="#link" onClick={(ev) => ev.preventDefault()}>
                            {" "}
                            Click here
                          </a>
                        </span> */}
                  </div>
                  <form
                    className="is-alter"
                    name="form-contract"
                  >
                    <Row>
                      <Col md="4" style={{ marginBottom: 20 }}>
                        <FormGroup>
                          <Label htmlFor="default-4" className="form-label">
                            Tipo de Pagamento
                          </Label>
                          <div className="form-control-wrap">
                            <div className="form-control-select">
                              <RSelect
                                name="payment_type"
                                value={selectsData.payment_type}
                                onChange={(val) =>
                                  setSelectsData({
                                    ...selectsData,
                                    payment_type: val.value,
                                  })
                                }
                                placeholder="Método de pagamento"
                                options={[
                                  { value: "pix", label: "Pix" },
                                  { value: "debito", label: "Débito" },
                                  { value: "credito", label: "Crédito" },
                                  { value: "boleto", label: "Boleto" },
                                ]}
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="4" style={{ marginBottom: 20 }}>
                        <FormGroup>
                          <Label htmlFor="default-4" className="form-label">
                            Parcelas
                          </Label>
                          <div className="form-control-wrap">
                            <div className="form-control-select">
                              <RSelect
                                name="installments"
                                onChange={(val) =>
                                  setSelectsData({
                                    ...selectsData,
                                    installments: val.value,
                                  })
                                }
                                placeholder="Selecione as parcelas"
                                options={[
                                  { value: "1", label: "Á vista" },
                                  { value: "2", label: "2x" },
                                  { value: "3", label: "3x" },
                                  { value: "4", label: "4x" },
                                  { value: "5", label: "5x" },
                                  { value: "6", label: "6x" },
                                  { value: "7", label: "7x" },
                                ]}
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label className="form-label" htmlFor="nationality">
                            Dia de vencimento
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="number"
                              id="due_date"
                              maxLength={2}
                              ref={register({ required: true })}
                              name="due_date"
                              placeholder="Dia ex: 01"
                              className="form-control-lg form-control"
                            />
                            {errors.due_date && (
                              <p className="invalid">
                                Esse campo é obrigatório.
                              </p>
                            )}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="nk-modal-action">
                      <Button
                        color="gray"
                        size="lg"
                        className="btn-mw mr-20"
                        style={{ marginRight: 20 }}
                        onClick={(ev) => {
                          ev.preventDefault();
                          setIsOpenPayment(false);
                        }}
                      >
                        Voltar
                      </Button>
                      <Button
                        color="primary"
                        size="lg"
                        type="submit"
                        className="btn-mw"
                      >
                        {loading ? (
                          <Spinner size="sm" color="light" />
                        ) : (
                          "Cadastrar"
                        )}
                      </Button>
                    </div>
                  </form>
                </div>
              </ModalBody>
              <ModalFooter className="bg-light">
                <div className="text-center w-100">
                  <p>Você poderá alterar essas informações quando quiser.</p>
                </div>
              </ModalFooter>
            </Modal>
            <form
              name="patient_form"
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <PageContainer>
                <Row>
                  <Col md={12} sm={12} lg={12} xl={12}>
                    <Block>
                      <PreviewCard>
                        <div className="text-center pt-4 pb-3">
                          <h6 className="overline-title overline-title-sap">
                            <span>Dados do paciente</span>
                          </h6>
                        </div>
                        <div className="text-center" style={{}}>
                          <div
                            onClick={() => setIsOpen(true)}
                            style={{
                              width: 140,
                              cursor: "pointer",
                              height: 140,
                              margin: "0 auto",
                              backgroundImage: `url("${avatar}")`,
                              backgroundSize: "cover",
                              backgroundPosition: "center center",
                              borderRadius: 200,
                            }}
                          />
                          <Button
                            onClick={(e) => {
                              setIsOpen(true);
                              e.preventDefault();
                            }}
                            className="btn-round btn-icon"
                            color="primary"
                            style={{
                              marginTop: 15,
                              boxShadow:
                                "10px 10px 45px -28px rgba(122,122,122,1)",
                            }}
                            size="lg"
                          >
                            <Icon name="camera" />
                          </Button>
                          <Row>
                            <Col md="12" style={{ marginTop: 20 }}>
                              <span>
                                Plano atual
                                <br />
                              </span>
                              {renderPlans()}
                            </Col>
                          </Row>
                          
                        </div>
                        <Row>
                          <Col md="3" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <Label htmlFor="default-4" className="form-label">
                                Plano
                              </Label>
                              <div className="form-control-wrap">
                                <div className="form-control-select">
                                  <RSelect
                                    name="plan"
                                    onChange={(val) => {
                                      setSelectsData({
                                        ...selectsData,
                                        plan: val.value,
                                      });
                                      setPlanSelected(
                                        plansOptions.filter(
                                          (i) => i.value === val.value
                                        )[0]
                                      );
                                    }}
                                    value={planSelected}
                                    placeholder="Selecione"
                                    options={[
                                      {
                                        value: "essencial",
                                        label: "Essencial",
                                      },
                                      { value: "premium", label: "Premium" },
                                      { value: "master", label: "Master" },
                                      { value: "platinum", label: "Platinum" },
                                    ]}
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <label className="form-label" htmlFor="name">
                                Nome
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  id="name"
                                  name="name"
                                  placeholder="Nome do paciente"
                                  ref={register({ required: true })}
                                  className="form-control-lg form-control"
                                />
                                {errors.name && (
                                  <p className="invalid">
                                    Esse campo é obrigatório.
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="3" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <label className="form-label">
                                Data de aniversário
                              </label>
                              <div className="form-control-wrap">
                                <Controller
                                  control={control}
                                  name="birthday"
                                  defaultValue=""
                                  render={({ field, value }) => (
                                    <DatePicker
                                      selected={birthday}
                                      dateFormat="dd/MM/yyyy"
                                      placeholderText="DD/MM/YYYY"
                                      name="birthday"
                                      customInput={
                                        <MaskedInput
                                          onChange={setBirthday}
                                          name="birthday"
                                          className="form-control-lg form-control"
                                          mask={[
                                            /\d/,
                                            /\d/,
                                            "/",
                                            /\d/,
                                            /\d/,
                                            "/",
                                            /\d/,
                                            /\d/,
                                            /\d/,

                                            /\d/,
                                          ]}
                                        />
                                      }
                                      locale={"ptBR"}
                                      onChange={setBirthday}
                                      className="form-control date-picker"
                                    />
                                  )}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="3" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <label
                                className="form-label"
                                htmlFor="nationality"
                              >
                                Nacionalidade
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  id="nationality"
                                  ref={register({ required: false })}
                                  name="nationality"
                                  placeholder="Nacionalidade do paciente"
                                  className="form-control-lg form-control"
                                />
                                {errors.nationality && (
                                  <p className="invalid">
                                    Esse campo é obrigatório.
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="3" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <Label htmlFor="default-4" className="form-label">
                                Estado civíl
                              </Label>
                              <div className="form-control-wrap">
                                <div className="form-control-select">
                                  <RSelect
                                    name="civil_state"
                                    value={
                                      civilStateOptions.filter(
                                        (i) =>
                                          i.value === selectsData.civil_state
                                      )[0]
                                    }
                                    onChange={(val) => {
                                      console.log(val);
                                      setSelectsData({
                                        ...selectsData,
                                        civil_state: val.value,
                                      });
                                    }}
                                    placeholder="Selecione"
                                    options={[
                                      { value: "casado", label: "Casado" },
                                      { value: "solteiro", label: "Solteiro" },
                                      {
                                        value: "divorciado",
                                        label: "Divorciado",
                                      },
                                      {
                                        value: "viuvo",
                                        label: "Viúvo",
                                      },
                                    ]}
                                    id="default-4"
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="3" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <label className="form-label" htmlFor="rg">
                                RG
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  id="rg"
                                  name="rg"
                                  ref={register({ required: false })}
                                  placeholder="RG do paciente"
                                  className="form-control-lg form-control"
                                />
                                {errors.rg && (
                                  <p className="invalid">
                                    Esse campo é obrigatório.
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="3" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <label className="form-label" htmlFor="cpf">
                                CPF
                              </label>
                              <div className="form-control-wrap">
                                <MaskedInput
                                  onChange={(e) =>
                                    addCpfs(e.target.value, "patient")
                                  }
                                  value={cpfs.cpf}
                                  placeholder="CPF do paciente"
                                  className="form-control-lg form-control"
                                  mask={[
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    ".",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    ".",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    "-",
                                    /\d/,
                                    /\d/,
                                  ]}
                                />

                                {errors.cpf && (
                                  <p className="invalid">
                                    Esse campo é obrigatório.
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="4" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <label className="form-label" htmlFor="cep">
                                CEP
                              </label>
                              <div className="form-control-wrap">
                                <MaskedInput
                                  id="cep"
                                  onChange={(e) =>
                                    addCeps(e.target.value, "patient")
                                  }
                                  onBlur={(val) =>
                                    fillCEP(val.target.value, "patient")
                                  }
                                  name="cep"
                                  value={ceps.cep}
                                  placeholder="CEP"
                                  className="form-control-lg form-control"
                                  mask={[
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    "-",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                  ]}
                                />
                                {errors.cep && (
                                  <p className="invalid">
                                    Esse campo é obrigatório.
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <label className="form-label" htmlFor="address">
                                Logradouro
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  id="address"
                                  name="address"
                                  ref={register({ required: false })}
                                  placeholder="Endereço do paciente"
                                  className="form-control-lg form-control"
                                />
                                {errors.address && (
                                  <p className="invalid">
                                    Esse campo é obrigatório.
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="2" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <label className="form-label" htmlFor="number">
                                Número
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  id="number"
                                  name="number"
                                  placeholder="Número"
                                  ref={register({ required: false })}
                                  className="form-control-lg form-control"
                                />
                                {errors.number && (
                                  <p className="invalid">
                                    Esse campo é obrigatório.
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="3" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <label
                                className="form-label"
                                htmlFor="address_complement"
                              >
                                Complemento
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  id="address_complement"
                                  name="address_complement"
                                  placeholder="Complemento"
                                  ref={register({ required: false })}
                                  className="form-control-lg form-control"
                                />
                                {errors.address_complement && (
                                  <p className="invalid">
                                    Esse campo é obrigatório.
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="3" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <label
                                className="form-label"
                                htmlFor="address_neighborhood"
                              >
                                Bairro
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  id="address_neighborhood"
                                  name="address_neighborhood"
                                  placeholder="Bairro"
                                  ref={register({ required: false })}
                                  className="form-control-lg form-control"
                                />
                                {errors.address_neighborhood && (
                                  <p className="invalid">
                                    Esse campo é obrigatório.
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                          </Col>

                          <Col md="4" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <label className="form-label" htmlFor="city">
                                Cidade
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  id="city"
                                  name="city"
                                  ref={register({ required: false })}
                                  placeholder="Cidade do paciente"
                                  className="form-control-lg form-control"
                                />
                                {errors.city && (
                                  <p className="invalid">
                                    Esse campo é obrigatório.
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="2" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <label className="form-label" htmlFor="state">
                                Estado
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  id="state"
                                  name="state"
                                  placeholder="Estado"
                                  className="form-control-lg form-control"
                                  ref={register({ required: false })}
                                />
                                {errors.state && (
                                  <p className="invalid">
                                    Esse campo é obrigatório.
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col sm={12}>
                            <FormGroup>
                              <label className="form-label">
                                Período do tratamento
                              </label>{" "}
                              <div className="form-control-wrap">
                                <div className="input-daterange date-picker-range input-group">
                                  <Controller
                                    control={control}
                                    name="rangeStart"
                                    defaultValue=""
                                    render={({ field }) => (
                                      <DatePicker
                                        selected={rangeStart}
                                        onChange={setRangeStart}
                                        selectsStart
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="DD/MM/YYYY"
                                        startDate={rangeStart}
                                        endDate={rangeEnd}
                                        name="rangeStart"
                                        customInput={
                                          <MaskedInput
                                            onChange={setRangeStart}
                                            name="rangeStart"
                                            className="form-control-lg form-control"
                                            mask={[
                                              /\d/,
                                              /\d/,
                                              "/",
                                              /\d/,
                                              /\d/,
                                              "/",
                                              /\d/,
                                              /\d/,
                                              /\d/,

                                              /\d/,
                                            ]}
                                          />
                                        }
                                        locale={"ptBR"}
                                        wrapperClassName="start-m"
                                        className="form-control"
                                      />
                                    )}
                                  />
                                  <div className="input-group-addon">ATÉ</div>
                                  <Controller
                                    control={control}
                                    name="rangeEnd"
                                    defaultValue=""
                                    render={({ field }) => (
                                      <DatePicker
                                        selected={rangeEnd}
                                        placeholderText="DD/MM/YYYY"
                                        name="rangeEnd"
                                        customInput={
                                          <MaskedInput
                                            onChange={setRangeEnd}
                                            name="rangeEnd"
                                            className="form-control-lg form-control"
                                            mask={[
                                              /\d/,
                                              /\d/,
                                              "/",
                                              /\d/,
                                              /\d/,
                                              "/",
                                              /\d/,
                                              /\d/,
                                              /\d/,

                                              /\d/,
                                            ]}
                                          />
                                        }
                                        onChange={setRangeEnd}
                                        startDate={rangeStart}
                                        endDate={rangeEnd}
                                        selectsEnd
                                        dateFormat="dd/MM/yyyy"
                                        minDate={rangeStart}
                                        locale={"ptBR"}
                                        wrapperClassName="end-m"
                                        className="form-control"
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>

                        {/* <div className="text-center pt-4 pb-3">
              <h6 className="overline-title overline-title-sap">
                <span>OR</span>
              </h6>
            </div> */}
                      </PreviewCard>
                    </Block>
                  </Col>
                  <Col md={12} sm={12} lg={12} xl={12}>
                    <Block>
                      <PreviewCard>
                        <div className="text-center pt-4 pb-3">
                          <h6 className="overline-title overline-title-sap">
                            <span>Dados do responsável</span>
                          </h6>
                        </div>
                        <Row>
                          <Col md="12" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <Label htmlFor="default-4" className="form-label">
                                Tipo de vínculo
                              </Label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  id="patient_link"
                                  ref={register({ required: false })}
                                  name="patient_link"
                                  placeholder="Ex: Pai; Mãe; Avó; Avô; Marido; Esposa..."
                                  className="form-control-lg form-control"
                                />
                                {errors.patient_link && (
                                  <p className="invalid">
                                    Esse campo é obrigatório.
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="8" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <label
                                className="form-label"
                                htmlFor="name_responsable"
                              >
                                Nome
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  id="name_responsable"
                                  name="name_responsable"
                                  ref={register({ required: false })}
                                  placeholder="Nome do responsável"
                                  className="form-control-lg form-control"
                                />
                                {errors.name_responsable && (
                                  <p className="invalid">
                                    Esse campo é obrigatório.
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="4" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <label
                                className="form-label"
                                htmlFor="nationality_responsable"
                              >
                                Nacionalidade
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  id="nationality_responsable"
                                  name="nationality_responsable"
                                  ref={register({ required: false })}
                                  placeholder="Nacionalidade do responsável"
                                  className="form-control-lg form-control"
                                />
                                {errors.nationality_responsable && (
                                  <p className="invalid">
                                    Esse campo é obrigatório.
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="4" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <Label htmlFor="default-4" className="form-label">
                                Estado civíl
                              </Label>
                              <div className="form-control-wrap">
                                <div className="form-control-select">
                                  <RSelect
                                    name="civil_state_responsable"
                                    value={
                                      civilStateOptions.filter(
                                        (i) =>
                                          i.value ===
                                          selectsData.civil_state_responsable
                                      )[0]
                                    }
                                    onChange={(val) =>
                                      setSelectsData({
                                        ...selectsData,
                                        civil_state_responsable: val.value,
                                      })
                                    }
                                    placeholder="Selecione"
                                    options={civilStateOptions}
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="4" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <label
                                className="form-label"
                                htmlFor="rg_responsable"
                              >
                                RG
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  ref={register({ required: false })}
                                  id="rg_responsable"
                                  name="rg_responsable"
                                  placeholder="RG do responsável"
                                  className="form-control-lg form-control"
                                />
                                {errors.rg_responsable && (
                                  <p className="invalid">
                                    Esse campo é obrigatório.
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="4" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <label
                                className="form-label"
                                htmlFor="cpf_responsable"
                              >
                                CPF
                              </label>
                              <div className="form-control-wrap">
                                <MaskedInput
                                  id="cpf_responsable"
                                  name="cpf_responsable"
                                  value={cpfs.cpf_responsable}
                                  placeholder="CPF do responsável"
                                  onChange={(e) =>
                                    addCpfs(e.target.value, "responsable")
                                  }
                                  className="form-control-lg form-control"
                                  mask={[
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    ".",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    ".",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    "-",
                                    /\d/,
                                    /\d/,
                                  ]}
                                />
                                {errors.cpf && (
                                  <p className="invalid">
                                    Esse campo é obrigatório.
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="4" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <label
                                className="form-label"
                                htmlFor="cep_responsable"
                              >
                                CEP
                              </label>
                              <div className="form-control-wrap">
                                <MaskedInput
                                  id="cep_responsable"
                                  name="cep_responsable"
                                  placeholder="CEP"
                                  value={ceps.cep_responsable}
                                  onBlur={(val) =>
                                    fillCEP(val.target.value, "responsable")
                                  }
                                  onChange={(e) =>
                                    addCeps(e.target.value, "responsable")
                                  }
                                  className="form-control-lg form-control"
                                  mask={[
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    "-",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                  ]}
                                />
                                {errors.cep_responsable && (
                                  <p className="invalid">
                                    Esse campo é obrigatório.
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <label
                                className="form-label"
                                htmlFor="address_responsable"
                              >
                                Logradouro
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  id="address_responsable"
                                  name="address_responsable"
                                  ref={register({ required: false })}
                                  placeholder="Endereço do paciente"
                                  className="form-control-lg form-control"
                                />
                                {errors.address_responsable && (
                                  <p className="invalid">
                                    Esse campo é obrigatório.
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="2" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <label
                                className="form-label"
                                htmlFor="address_number_responsable"
                              >
                                Número
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  id="address_number_responsable"
                                  name="address_number_responsable"
                                  ref={register({ required: false })}
                                  placeholder="Número"
                                  className="form-control-lg form-control"
                                />
                                {errors.address_number_responsable && (
                                  <p className="invalid">
                                    Esse campo é obrigatório.
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="3" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <label
                                className="form-label"
                                htmlFor="address_complement_responsable"
                              >
                                Complemento
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  id="address_complement_responsable"
                                  name="address_complement_responsable"
                                  ref={register({ required: false })}
                                  placeholder="Complemento"
                                  className="form-control-lg form-control"
                                />
                                {errors.address_complement && (
                                  <p className="invalid">
                                    Esse campo é obrigatório.
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="3" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <label
                                className="form-label"
                                htmlFor="address_neighborhood_responsable"
                              >
                                Bairro
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  id="address_neighborhood_responsable"
                                  name="address_neighborhood_responsable"
                                  ref={register({ required: false })}
                                  placeholder="Bairro"
                                  className="form-control-lg form-control"
                                />
                                {errors.address_neighborhood_responsable && (
                                  <p className="invalid">
                                    Esse campo é obrigatório.
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                          </Col>

                          <Col md="3" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <label
                                className="form-label"
                                htmlFor="state_responsable"
                              >
                                Estado
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  id="state_responsable"
                                  name="state_responsable"
                                  placeholder="Estado"
                                  ref={register({ required: false })}
                                  className="form-control-lg form-control"
                                />
                                {errors.state_responsable && (
                                  <p className="invalid">
                                    Esse campo é obrigatório.
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="3" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <label
                                className="form-label"
                                htmlFor="city_responsable"
                              >
                                Cidade
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  id="city_responsable"
                                  name="city_responsable"
                                  ref={register({ required: false })}
                                  placeholder="Cidade do responsável"
                                  className="form-control-lg form-control"
                                />
                                {errors.city_responsable && (
                                  <p className="invalid">
                                    Esse campo é obrigatório.
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <label
                                className="form-label"
                                htmlFor="phone_responsable"
                              >
                                Telefone
                              </label>
                              {phones.map((phone, index) => {
                                return (
                                  <Row style={{ marginBottom: 15 }}>
                                    <Col md={5}>
                                      <div className="form-control-wrap">
                                        <MaskedInput
                                          type="text"
                                          id="phone_responsable"
                                          onChange={(e) => {
                                            let newArr = phones.map(
                                              (item, i) => {
                                                if (index === i) {
                                                  return {
                                                    ...item,
                                                    phone: e.target.value,
                                                  };
                                                } else {
                                                  return item;
                                                }
                                              }
                                            );
                                            setPhones(newArr);
                                          }}
                                          value={phone.phone}
                                          name="phone_responsable"
                                          placeholder="Telefone"
                                          className="form-control-lg form-control"
                                          mask={[
                                            "(",
                                            /[1-9]/,
                                            /\d/,
                                            ")",
                                            " ",
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            "-",
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                          ]}
                                        />
                                      </div>
                                    </Col>
                                    <Col md={5}>
                                      <div className="form-control-wrap">
                                        <input
                                          type="text"
                                          id="phone_responsable_name"
                                          name="phone_responsable_name"
                                          onChange={(e) => {
                                            let newArr = phones.map(
                                              (item, i) => {
                                                if (index === i) {
                                                  return {
                                                    ...item,
                                                    name: e.target.value,
                                                  };
                                                } else {
                                                  return item;
                                                }
                                              }
                                            );
                                            setPhones(newArr);
                                          }}
                                          value={phone.name}
                                          placeholder="Responsável"
                                          ref={register({ required: false })}
                                          className="form-control-lg form-control"
                                        />
                                      </div>
                                    </Col>
                                    <Col md={2}>
                                      {index === phones.length - 1 ? (
                                        <Button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            addPhone();
                                          }}
                                          className="btn-round btn-icon"
                                          color="primary"
                                          size="sm"
                                        >
                                          <Icon name="plus" />
                                        </Button>
                                      ) : (
                                        <Button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            let p = phones.filter((r, ri) => {
                                              return index !== ri;
                                            });
                                            setPhones(p);
                                          }}
                                          className="btn-round btn-icon"
                                          color="primary"
                                          size="sm"
                                        >
                                          <Icon name="cross" />
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                );
                              })}
                            </FormGroup>
                          </Col>
                          <Col md="6" style={{ marginBottom: 20 }}>
                            <FormGroup>
                              <label
                                className="form-label"
                                htmlFor="email_responsable"
                              >
                                E-mail do responsável
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="email"
                                  id="email_responsable"
                                  name="email_responsable"
                                  placeholder="E-mail do responsável"
                                  ref={register({ required: false })}
                                  className="form-control-lg form-control"
                                />
                                {errors.email_responsable && (
                                  <p className="invalid">
                                    Esse campo é obrigatório.
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md={12}>
                            <FormGroup>
                              <Button
                                color="primary"
                                size="lg"
                                style={{
                                  marginTop: 20,
                                }}
                                className="btn-block"
                              >
                                Salvar
                              </Button>
                            </FormGroup>
                          </Col>
                        </Row>
                      </PreviewCard>
                    </Block>
                  </Col>
                </Row>
              </PageContainer>
            </form>
          </React.Fragment>
        </Content>
      )}
    </React.Fragment>
  );
};
export default UserDetailsPage;
