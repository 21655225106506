import React, { useEffect, useState } from "react";
import Head from "../layout/head/Head";
import Content from "../layout/content/Content";
import RecentActivity from "../components/partials/sales/recent-activity/Activity";
import CalenderApp from "../components/partials/calender/Calender";
import DataCard from "../components/partials/default/DataCard";
import {
  Card,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  BlockBetween,
} from "../components/Component";
import {
  DefaultCustomerChart,
  DefaultOrderChart,
  DefaultRevenueChart,
  DefaultVisitorChart,
} from "../components/partials/charts/default/DefaultCharts";
import {
  eventOptions,
  events,
} from "../components/partials/calender/CalenderData";
import axios from "axios";

const Homepage = () => {
  const [sm, updateSm] = useState(false);
  const [mockEvents, updateEvent] = useState(events);
const [data, setData] = useState([])
  const getPatients = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/patients?populate=*&pagination[pageSize]=99999`)
      .then((response) => {
      
        setData(response.data.data);
      });
  };

  
  const getPermissions = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/users/me?populate=role`, {
        headers: {
          Authorization: `bearer ${localStorage.getItem("accessToken")}`
        }
      })
      .then((response) => {
      });
  };

  const getUser = () => {
    const user = localStorage.getItem("user");
    console.log(user)
    return JSON.parse(user)
  }

  const filterActive = () => {
    return data.filter(item => item.attributes.contracts.data[0].attributes.is_active).length
  }

  useEffect(() => {
    getPatients()
    getPermissions()
  }, [])
  return (
    <React.Fragment>
      <Head title="Homepage"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Seja bem-vindo(a), {getUser().name}
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${
                    sm ? "active" : ""
                  }`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v" />
                </Button>
                <div
                  className="toggle-expand-content"
                  style={{ display: sm ? "block" : "none" }}
                >
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button color="primary">
                        <Icon name="reports" />
                        <span>Relatórios</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row className="g-gs">
            <Col xxl="4" sm="4" md="4">
              <DataCard
                title="Pacientes Ativos"
                chart={<DefaultOrderChart />}
                amount={filterActive()}
              />
            </Col>
            <Col xxl="4" sm="4" md="4">
              <DataCard
                title="Tickets Abertos"
                chart={<DefaultCustomerChart />}
                amount={"10"}
              />
            </Col>
            <Col xxl="4" sm="4" md="4">
              <DataCard
                title="Tickets"
                chart={<DefaultVisitorChart />}
                amount={"23.485"}
              />
              </Col>
            
            <Col md={8}>
              <Card className="card-full">
                <RecentActivity />
              </Card>
            </Col>
            <Col md={4}>
              <Card className="card-full ">
                <div className="card-inner border-bottom">
                  <div className="card-title-group">
                    <div className="card-title">
                      <h6 className="title">Agenda</h6>
                    </div>
                  </div>
                </div>
                <div className="card-inner">
                  <CalenderApp events={mockEvents} />
                </div>
              </Card>
            </Col>
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default Homepage;
